@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}